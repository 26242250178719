// prefer default export if available
const preferDefault = m => (m && m.default) || m

exports.components = {
  "component---src-pages-404-js": () => import("./../../../src/pages/404.js" /* webpackChunkName: "component---src-pages-404-js" */),
  "component---src-pages-contacto-js": () => import("./../../../src/pages/contacto.js" /* webpackChunkName: "component---src-pages-contacto-js" */),
  "component---src-pages-index-js": () => import("./../../../src/pages/index.js" /* webpackChunkName: "component---src-pages-index-js" */),
  "component---src-pages-thankyou-contacto-js": () => import("./../../../src/pages/thankyouContacto.js" /* webpackChunkName: "component---src-pages-thankyou-contacto-js" */),
  "component---src-pages-thankyou-hub-js": () => import("./../../../src/pages/thankyouHub.js" /* webpackChunkName: "component---src-pages-thankyou-hub-js" */),
  "component---src-pages-thankyou-insurgentes-js": () => import("./../../../src/pages/thankyouInsurgentes.js" /* webpackChunkName: "component---src-pages-thankyou-insurgentes-js" */),
  "component---src-pages-thankyou-one-js": () => import("./../../../src/pages/thankyouOne.js" /* webpackChunkName: "component---src-pages-thankyou-one-js" */),
  "component---src-pages-thankyou-reforma-js": () => import("./../../../src/pages/thankyouReforma.js" /* webpackChunkName: "component---src-pages-thankyou-reforma-js" */),
  "component---src-pages-thankyou-santafe-js": () => import("./../../../src/pages/thankyouSantafe.js" /* webpackChunkName: "component---src-pages-thankyou-santafe-js" */),
  "component---src-templates-development-index-js": () => import("./../../../src/templates/Development/index.js" /* webpackChunkName: "component---src-templates-development-index-js" */)
}

